<template>
  <v-col class="mx-auto" style="max-width: 600px;">
    <h3>User info</h3>
    <v-progress-circular v-if="$store.AppStore.isCurrentUserLoading"
                         indeterminate
                         color="grey lighten-1"
                         class="mx-auto text-center"
                         style="width: 100%;"
    ></v-progress-circular>
    <v-row class="mx-0 my-2" v-if="!!user && !$store.AppStore.isCurrentUserLoading">
      <v-card class="my-2" outlined style="width: 100%;">
        <v-card-text>
          <v-row class="ma-0">
            <v-col class="pa-0 my-1 font-weight-bold" :cols="12 - xsCols" :sm="12 - smCols">Name</v-col>
            <v-col class="pa-0 my-1" :cols="xsCols" :sm="smCols">{{user.name}}</v-col>
            <v-col class="pa-0 my-1 font-weight-bold" :cols="12 - xsCols" :sm="12 - smCols">Email</v-col>
            <v-col class="pa-0 my-1" :cols="xsCols" :sm="smCols">{{user.email}}</v-col>
            <v-col class="pa-0 my-1 font-weight-bold" :cols="12 - xsCols" :sm="12 - smCols">Created at</v-col>
            <v-col class="pa-0 my-1" :cols="xsCols" :sm="smCols">{{user.created_at}}</v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="openUpdatePasswordDialog" :loading="$store.AppStore.isUpdatePasswordLoading">Change password</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
    <h3>Menus subscriptions</h3>
    <v-progress-circular v-if="$store.AppStore.isCurrentUserLoading || $store.AppStore.isCurrentMenusLoading"
                         indeterminate
                         color="grey lighten-1"
                         class="mx-auto text-center"
                         style="width: 100%;"
    ></v-progress-circular>
    <v-row class="mx-0 my-2" v-if="!!menus && !$store.AppStore.isCurrentMenusLoading">
      <v-card v-for="(item, i) of menus" :key="`menu-${i}`" class="my-2" outlined style="width: 100%;">
        <v-card-text>
          <v-row class="ma-0">
            <v-col class="pa-0 my-1 font-weight-bold" :cols="12 - xsCols" :sm="12 - smCols">Name</v-col>
            <v-col class="pa-0 my-1" :cols="xsCols" :sm="smCols">{{item.menuName}}</v-col>
            <v-col class="pa-0 my-1 font-weight-bold" :cols="12 - xsCols" :sm="12 - smCols">Status</v-col>
            <v-col class="pa-0 my-1" :cols="xsCols" :sm="smCols">Added</v-col>
            <v-col class="pa-0 my-1 font-weight-bold" :cols="12 - xsCols" :sm="12 - smCols">Total cost</v-col>
            <v-col class="pa-0 my-1" :cols="xsCols" :sm="smCols">{{item.total_cost}}</v-col>
            <v-col class="pa-0 my-1 font-weight-bold" :cols="12 - xsCols" :sm="12 - smCols">Month count</v-col>
            <v-col class="pa-0 my-1" :cols="xsCols" :sm="smCols">{{item.months_count}}</v-col>
            <v-col class="pa-0 my-1 font-weight-bold" :cols="12 - xsCols" :sm="12 - smCols">Created at</v-col>
            <v-col class="pa-0 my-1" :cols="xsCols" :sm="smCols">{{item.created_at}}</v-col>
            <v-col class="pa-0 my-1 font-weight-bold" :cols="12 - xsCols" :sm="12 - smCols">Started at</v-col>
            <v-col class="pa-0 my-1" :cols="xsCols" :sm="smCols">{{item.started_at}}</v-col>
            <v-col class="pa-0 my-1 font-weight-bold" :cols="12 - xsCols" :sm="12 - smCols">Finished at</v-col>
            <v-col class="pa-0 my-1" :cols="xsCols" :sm="smCols">{{item.finished_at}}</v-col>
            <v-col class="pa-0 my-1 font-weight-bold" :cols="12 - xsCols" :sm="12 - smCols">Meal types</v-col>
            <v-col class="pa-0 my-1" :cols="xsCols" :sm="smCols">{{item.meal_types.join(', ')}}</v-col>
            <v-col class="pa-0 my-1 font-weight-bold" :cols="12 - xsCols" :sm="12 - smCols">Periodicity</v-col>
            <v-col class="pa-0 my-1" :cols="xsCols" :sm="smCols">{{item.periodicity.replace('_', ' ')}}</v-col>
            <v-col v-if="item.promocode" class="pa-0 my-1 font-weight-bold" :cols="12 - xsCols" :sm="12 - smCols">Promocode</v-col>
            <v-col v-if="item.promocode" class="pa-0 my-1" :cols="xsCols" :sm="smCols">{{item.promocode}}</v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="removeMenu(item.menu_id)" :loading="$store.AppStore.removeMenuRequestData.menuId === item.menu_id && $store.AppStore.isRemoveMenuLoading">Remove</v-btn>
        </v-card-actions>
      </v-card>
      <template v-if="geNotAddingMenus && geNotAddingMenus.length">
        <v-card v-for="(item, i) of geNotAddingMenus" :key="`not-adding-menu-${i}`" class="my-2" outlined style="width: 100%;">
          <v-card-text>
            <v-row class="ma-0">
              <v-col class="pa-0 my-1 font-weight-bold" :cols="12 - xsCols" :sm="12 - smCols">Name</v-col>
              <v-col class="pa-0 my-1" :cols="xsCols" :sm="smCols">{{item.name}}</v-col>
              <v-col class="pa-0 my-1 font-weight-bold" :cols="12 - xsCols" :sm="12 - smCols">Status</v-col>
              <v-col class="pa-0 my-1" :cols="xsCols" :sm="smCols">Not added</v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="addMenu(item.id)" :loading="$store.AppStore.addMenuRequestData.menuId === item.id && $store.AppStore.isAddMenuLoading">Add</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-row>
    <h3>Special menus</h3>
    <v-progress-circular v-if="$store.AppStore.isCurrentUserLoading || $store.AppStore.isCurrentMenusLoading || $store.AppStore.isCurrentSpecialMenusLoading"
                         indeterminate
                         color="grey lighten-1"
                         class="mx-auto text-center"
                         style="width: 100%;"
    ></v-progress-circular>
    <v-row class="mx-0 my-2" v-if="!!specialMenus && !$store.AppStore.isCurrentSpecialMenusLoading">
      <v-card v-for="(item, i) of specialMenus" :key="`menu-${i}`" class="my-2" outlined style="width: 100%;">
        <v-card-text>
          <v-row class="ma-0">
            <v-col class="pa-0 my-1 font-weight-bold" :cols="12 - xsCols" :sm="12 - smCols">Name</v-col>
            <v-col class="pa-0 my-1" :cols="xsCols" :sm="smCols">{{item.menuName}}</v-col>
            <v-col class="pa-0 my-1 font-weight-bold" :cols="12 - xsCols" :sm="12 - smCols">Status</v-col>
            <v-col class="pa-0 my-1" :cols="xsCols" :sm="smCols">Added</v-col>
            <v-col class="pa-0 my-1 font-weight-bold" :cols="12 - xsCols" :sm="12 - smCols">Total cost</v-col>
            <v-col class="pa-0 my-1" :cols="xsCols" :sm="smCols">{{item.total_cost}}</v-col>
            <v-col class="pa-0 my-1 font-weight-bold" :cols="12 - xsCols" :sm="12 - smCols">Created at</v-col>
            <v-col class="pa-0 my-1" :cols="xsCols" :sm="smCols">{{item.created_at}}</v-col>
            <v-col v-if="item.promocode" class="pa-0 my-1 font-weight-bold" :cols="12 - xsCols" :sm="12 - smCols">Promocode</v-col>
            <v-col v-if="item.promocode" class="pa-0 my-1" :cols="xsCols" :sm="smCols">{{item.promocode}}</v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="removeSpecialMenu(item.recipe_set_id)" :loading="$store.AppStore.removeSpecialMenuRequestData.menuId === item.recipe_set_id && $store.AppStore.isRemoveSpecialMenuLoading">Remove</v-btn>
        </v-card-actions>
      </v-card>
      <template v-if="geNotAddingSpecialMenus && geNotAddingSpecialMenus.length">
        <v-card v-for="(item, i) of geNotAddingSpecialMenus" :key="`not-adding-special-menu-${i}`" class="my-2" outlined style="width: 100%;">
          <v-card-text>
            <v-row class="ma-0">
              <v-col class="pa-0 my-1 font-weight-bold" :cols="12 - xsCols" :sm="12 - smCols">Name</v-col>
              <v-col class="pa-0 my-1" :cols="xsCols" :sm="smCols">{{item.name}}</v-col>
              <v-col class="pa-0 my-1 font-weight-bold" :cols="12 - xsCols" :sm="12 - smCols">Status</v-col>
              <v-col class="pa-0 my-1" :cols="xsCols" :sm="smCols">Not added</v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="addSpecialMenu(item.id)" :loading="$store.AppStore.addSpecialMenuRequestData.menuId === item.id && $store.AppStore.isAddSpecialMenuLoading">Add</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-row>
    <v-dialog v-model="updatePasswordDialogModel" :max-width="400" persistent>
      <v-card>
        <v-card-title>
          <span>Updating password</span>
          <v-spacer/>
          <v-btn icon @click="updatePasswordDialogModel = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-2">
          <v-text-field outlined dense hide-details label="New password" v-model="newPassword"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray darken-1" text @click="cancelUpdatePassword">
            Cancel
          </v-btn>
          <v-btn color="primary darken-1" text @click="updatePassword">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
export default {
  name: 'User',
  computed: {
    user() {
      return this.$api.main.getCurrentUser();
    },
    menus() {
      return this.$api.main.getCurrentMenus();
    },
    specialMenus() {
      return this.$api.main.getCurrentSpecialMenus();
    },
    geNotAddingMenus() {
      return this.$api.main.geNotAddingMenus();
    },
    geNotAddingSpecialMenus() {
      return this.$api.main.geNotAddingSpecialMenus();
    },
  },
  data() {
    return {
      xsCols: 7,
      smCols: 8,
      updatePasswordDialogModel: false,
      newPassword: '',
    };
  },
  async created() {
    this.$api.main.loadUser(this.$route.params.id);
    await this.$api.main.getRecipesSets();
  },
  methods: {
    async addMenu(id) {
      await this.$api.main.addMenu(id);
    },
    async addSpecialMenu(id) {
      await this.$api.main.addSpecialMenu(id);
    },
    async removeMenu(id) {
      await this.$api.main.removeMenu(id);
    },
    async removeSpecialMenu(id) {
      await this.$api.main.removeSpecialMenu(id);
    },
    openUpdatePasswordDialog() {
      this.newPassword = '';
      this.updatePasswordDialogModel = true;
    },
    cancelUpdatePassword() {
      this.updatePasswordDialogModel = false;
    },
    async updatePassword() {
      this.updatePasswordDialogModel = false;
      await this.$api.main.updatePassword(this.newPassword);
    },
  },
};
</script>
